import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import axios from 'axios';
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
function App() {
  return (
    <div className="App">
      <OptInForm />
    </div>
  );
}





function OptInForm() {
  const [name, setName] = useState('');
  const [phone, setphone] = useState('');
  const [checked, setOptIn] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isFormValid = name !== '' && phone !== '' && checked;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    const config = {
      method: "post",
      url: `https://canonrsna.com/2023/api/optInTwilio`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        name,
        phone,
        checked,
      },
    };


    try {
      const response = await axios(config);

      if (response.status === 200) {
        console.log('API Response:', config, response.data);
        setSubmitted(true);
      } else {
        // Handle API error here
        console.error('API Error:', response.statusText);
      }
    } catch (error) {
      console.error('API Request Failed:', error);
    }
  };





  if (submitted) {
    return (
      <div className="opt-in-form-container">
        <div className="logo">
          <img src="https://canonrsna.com/canon_logos/canon@0.5x.png" alt="Canon Medical" />
        </div>


        <form onSubmit={() => { }}>
          <h2 className="campaign">Canon Medical<br />
            <em className="">RSNA 2023 Reception Desk</em>
          </h2>
          <p className="">You have opted-in to receive messages from the Canon RSNA Reception Desk during RSNA</p>

          <img className='rsna' src="https://www.rsna.org/-/media/Images/RSNA/Annual-meeting/exhibitors-and-sponsors/LogosAndPromotionalTools2023/CRTE847_Exhibitor_2023_Social_Media_Graphics_1128x191.ashx?la=en&hash=E5453565798F4A8FE4C254CA51F018C550F575FB" alt="RSNA" />

          <h2>Thank you for submitting!</h2>


        </form>
      </div>
    );
  }


  return (
    <div className="opt-in-form-container">
      <div className="logo">
        <img src="https://canonrsna.com/canon_logos/canon@0.5x.png" alt="Canon Medical" />
      </div>
      <form onSubmit={handleSubmit}>
        <h2 className="campaign">Canon Medical<br />
          <em className="">RSNA 2023 Reception Desk</em>
        </h2>
        <p className="">Please use the form below to opt-in to receive messages from the Canon RSNA Reception Desk during RSNA</p>

        <img className='rsna' src="https://www.rsna.org/-/media/Images/RSNA/Annual-meeting/exhibitors-and-sponsors/LogosAndPromotionalTools2023/CRTE847_Exhibitor_2023_Social_Media_Graphics_1128x191.ashx?la=en&hash=E5453565798F4A8FE4C254CA51F018C550F575FB" alt="RSNA" />

        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
            required
          />
        </div>
        <div className="form-group optIN">
          <label htmlFor="checked">
            <input
              type="checkbox"
              id="checked"
              checked={checked}
              onChange={(e) => setOptIn(e.target.checked)}
              required
            />

            I agree to recieve messages from the Canon RSNA Reception Desk application at the phone number provided
            above during RSNA.  I understand I will receive messages and that data rates may apply. Reply STOP to opt out to  (866) 891-7762.
          </label>
        </div>
        <button type="submit" disabled={!isFormValid}>
          Submit
        </button>
      </form>
      {submitted && <p className="thank-you-text">Thank you for submitting!</p>}
    </div>
  );
}




export default App;
